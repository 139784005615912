<template lang="pug">
div
  v-text-field(
    label="Amount"
    v-model="formData.value"
    :error="formErrors.hasError('value')"
    :error-messages="formErrors.fields.value"
  )
  v-text-field(
    label="Label"
    v-model="formData.label"
    :error="formErrors.hasError('label')"
    :error-messages="formErrors.fields.label"
  )
  v-text-field(
    label="Subject"
    v-model="formData.subject"
    :error="formErrors.hasError('subject')"
    :error-messages="formErrors.fields.subject"
  )
  v-textarea(
    outlined
    label="Content"
    :rows="12"
    placeholder="Type your text here..."
    v-model="formData.content"
    :error="formErrors.hasError('content')"
    :error-messages="formErrors.fields.content"
  )
  v-textarea(
    label="Notes"
    outlined
    placeholder="Indicate why the candidate needs this payment"
    v-model="formData.notes"
    :error="formErrors.hasError('notes')"
    :error-messages="formErrors.fields.notes"
  ).payment-notes

</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    formErrors: Object
  },

  data:() => ({
    formData: {
      amount: null,
      label: 'custom_ideal',
      subject: 'NuTheorie betalingsverzoek',
      content: `Je hebt je aangemeld voor een cursus bij NuTheorie. Er staat een bedrag bij ons open welke nog door jou voldaan moet worden. Zorg dat je deze zo spoedig mogelijk betaalt, dan kunnen we voor je aan de slag.<br /> <br />Betaal via de onderstaande link:`,
      notes: null
    }
  }),

  mounted() {
    this.setData()
  },

  methods: {
    setData() {
      if(!this.item) return
      this.formData.value = this.item.value || ''
      this.formData.label = this.item.label || ''
      this.formData.subject = this.item.subject
      this.formData.content = this.item.content
      this.formData.notes = this.item.notes
    },

    getData() {
      return this.formData
    }
  }
}
</script>